// brand colors
$brand: #2586d8;
$secondary: #ff901f;

// color tones
$info: $brand;
$success: #43ad4b;
$caution: #fdc132;
$warning: #ff901f;
$danger: #e74c3c;
$royal: #8e44ad;

// greyed tones
$lightest: #fdfdff;
$lighter: #f8f9fd;
$light: #f2f3f7;

$light-grey: #d8dee4;
$darker-grey: #b5bec5;
$grey: #a0abb4;
$dark-grey: #474e53;
$dark: #232725;
$background: #edeff4;
$gradient: #121314;

$darken: 7.5%;
$shadow: rgba($dark-grey, 0.2);

// transitions
$fast: 200ms;
$slow: 400ms;

// padding
$padding: 24px;

// input

$input-height: 48px;
$input-small-height: 32px;

// checkout widths
$checkout-width: 845px;

$all-buttons: 'button, input[type="button"], input[type="reset"], input[type="submit"]';
$all-text-inputs: 'textarea, input[type="password"], input[type="text"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="url"]';

:root {
    --grey: #a0abb4;

    --gutter-size: 16px;
    --nagative-gutter-size: -16px;
    --transition-fast: 200ms;

    --site-mobile-width: 768px;
    --site-tablet-width: 1024px;
    --site-width: 1236px;

    --success: #44ad4b;
    --success-rgb: 68, 173, 75;
    --success-darkened: #3ea045; // 7.5% darkened

    --warning: #ff901f;
    --warning-rgb: 255, 144, 31;

    --danger: #e84b3c;
    --danger-rgb: 232, 75, 60;

    --brand: #2586d8;
    --brand-rgb: 37, 134, 216;

    --brand-offset: #1876c5;
    --brand-offset-rgb: 24, 118, 197;

    --dark-grey: #474e53;
    --dark-grey-rgb: 71, 78, 83;

    --text-light: var(--grey);

    --border: #d8dee4;
    --border-active: var(--brand);

    --background: #f2f3f7;
    --background-rgb: 242, 243, 247;
    --background-lighter: #f8f9fd;

    --menu-height: 54px;
    --header-height: 78px;
    --header-height-mobile: 60px;

    --site-top-height: calc(var(--menu-height) + var(--header-height));

    --fast: 200ms; // animation speed
    --medium: 500; // font-weight

    --input-radius: 4px;

    /* customizable variables */
    --site-top-background: var(--brand);
    --site-menu-background: var(--background);
}

// load mixins and extendables
@import '../mixins/clearfix';
@import '../mixins/triangle';

@import '../mixins/respond';

@import '../mixins/button/base';
@import '../mixins/button/action';
@import '../mixins/button/inline';

@import '../mixins/font';
@import '../mixins/structure';
@import '../mixins/dropdown';
@import '../mixins/select';
@import '../mixins/nowrap';
@import '../mixins/scrollbar';
@import '../mixins/loading';
@import '../mixins/card';
